<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        {{ $t('CheckingSetList') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          class='d-none d-md-block'
          @click='isAddNewCheckingSet = true'
        >
          <v-icon class='me-1'>
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('AddCheckingSetList') }}
        </v-btn>
        <v-btn
          color='primary'
          @click='isAddNewCheckingSet = true'
          class='me-2 d-block d-md-none'
          fab
          icon
          outlined
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2'>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-text-field
            :label="$t('search')"
            v-model='searchtext'
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-select
            v-model.trim='checking_status_id'
            :items='statusList'
            :label="$t('status')"
            :item-text='$i18n.locale'
            outlined
            dense
            item-value='value'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :server-items-length='totalDataTableList'
        :footer-props='footer'
        :loading='loading'
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]='{ index }'>
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.checkingset_cost`]='{ item }'>
          {{ item.checkingset_cost | formatPrice }}
        </template>
        <template v-slot:[`item.checkingset_code`]='{ item }'>
          <router-link
            class='font-weight-medium text-decoration-none'
            :to="{ name: 'checkingSetDetail', params: { id: item.checkingset_id	 } }"
          >
            {{ item.checkingset_code
            }}
          </router-link>
        </template>
        <template v-slot:[`item.checkingset_active_id`]='{ item }'>
          <StatusBlock :status='item.checkingset_active_id' />
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <router-link
            class='font-weight-medium text-decoration-none'
            :to="{ name: 'checkingSetDetail', params: { id: item.checkingset_id	 } }"
          >
            <v-tooltip
              color='#212121'
              top
            >
              <template v-slot:activator='{ on, attrs }'>
                <v-btn
                  icon
                  color='primary'
                  v-bind='attrs'
                  v-on='on'
                >
                  <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                </v-btn>
              </template>
              {{ $t('detail') }}
            </v-tooltip>
          </router-link>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                @click='dataEdit = item; isEditCheckingSet = true'
                v-on='on'
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('edit') }}
          </v-tooltip>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                v-on='on'
                @click='dataEdit = item; isUpdateStatus = true'
              >
                <v-icon>{{ item.checkingset_active_id == 1 ? icons.mdiDeleteOutline : icons.mdiCheck
                  }}
                </v-icon>
              </v-btn>
            </template>
            {{ item.checkingset_active_id == 1 ? $t('suspend') : $t('normal') }}
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data='options.page'
        :page-count='totalPage'
        :segment-id='+segmentId '
        :count-list='dataTableList.length'
        :total='+totalDataTableList'
        @pageChanged='onChangePage'
        @itemChanged='(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}'
      />
    </v-card>
    <AddNewCheckingSet v-model='isAddNewCheckingSet' @onAdd='fetchDataTable' />
    <EditCheckingSet v-model='isEditCheckingSet' :dataEdit='dataEdit'
                     @onUpdate='fetchDataTable' />
    <CheckingSetUpdateStatus v-model='isUpdateStatus' :data-edit='dataEdit'
                             @update:isUpdateStatus='fetchDataTable' />
  </div>
</template>
<script>
import Pagination from '@/components/basicComponents/Pagination.vue'
import useCheckingSetList from './useCheckingSetList'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewCheckingSet from './AddNewCheckingSet.vue'
import EditCheckingSet from './EditCheckingSet'
import CheckingSetUpdateStatus from './CheckingSetUpdateStatus'
import { formatPrice } from '@/plugins/filters'

export default {
  components: {
    Pagination,
    StatusBlock,
    EditCheckingSet,
    CheckingSetUpdateStatus,
    AddNewCheckingSet,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...useCheckingSetList(),
    }
  },
}
</script>

