<template>
  <div>
    <v-dialog v-model='isEditCheckingSet' persistent max-width='450'>
      <v-card>
        <v-card-title>
          {{ $t('editCheckingSet') }}
        </v-card-title>
        <v-form
          ref='formEditChecking'
          @submit.prevent='updateCheckingSet'
        >
          <v-card-text>
            <v-text-field
              v-model='dataEditLocal.checkingset_name'
              :rules='[required]'
              outlined
              :label="$t('CheckingSetName')"
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isEditCheckingSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import checkingSet from '@/api/checkingSet/checkingSet'
import store from '@/store'

export default {
  model: {
    prop: 'isEditCheckingSet',
    event: 'update:isEditCheckingSet',
  },
  props: {
    isEditCheckingSet: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const dataEditLocal = ref({})
    const loading = ref(false)
    const formEditChecking = ref(null)

    const updateCheckingSet = () => {
      const isFormValid = formEditChecking.value.validate()
      if (!isFormValid) return
      loading.value = true

      checkingSet.CheckingSetUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isEditCheckingSet', false)
        emit('onUpdate')
        loading.value = false
      }).catch(err => {
        console.log('error : ', err)
        loading.value = false
      })

    }

    watch(() => props.isEditCheckingSet, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
      }
    })
    return {
      dataEditLocal,
      required,
      updateCheckingSet,
      formEditChecking,
      loading,
    }
  },

}
</script>

<style scoped>

</style>
