<template>
  <div>
    <v-dialog
      v-model='isAddNewCheckingSet'
      persistent
      max-width='450'
    >
      <v-card>
        <v-card-title>
          {{ $t('addCheckingSet') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-form
          ref='formAddChecking'
          @submit.prevent='createChecking'
        >
          <v-card-text>
            <v-text-field
              v-model='checkingset_name'
              :rules='[required]'
              outlined
              :label="$t('CheckingSetName')"
              dense
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              @click="$emit('update:isAddNewCheckingSet',false)"
              outlined
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, textValidation } from '@/@core/utils/validation'
import checkingSet from '@/api/checkingSet/checkingSet'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewCheckingSet',
    event: 'update:isAddNewCheckingSet',
  },
  props: {
    isAddNewCheckingSet: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formAddChecking = ref(null)
    const checkingset_name = ref('')
    const loading = ref(false)
    const clearData = () => {
      checkingset_name.value = ''
    }


    const createChecking = () => {
      const isFormValid = formAddChecking.value.validate()
      if (!isFormValid) return
      loading.value = true
      checkingSet.checkingSetAdd({
        checkingset_name: checkingset_name.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:isAddNewCheckingSet', false)
        emit('onAdd')
        loading.value = false
        clearData()
      })
    }


    watch(() => props.isAddNewCheckingSet, () => {
      if (props.isAddNewCheckingSet) {
        clearData()
      }
    })


    return {
      formAddChecking,
      checkingset_name,
      required,
      textValidation,
      createChecking,
      loading,
      clearData,
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
